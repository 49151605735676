.form-check {
  label {
    margin-left: 12px !important;
    margin-right: 4px !important;
    color: black !important;
    font-family: 'urbano-regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: right;
  }
}
