.discover-section {
  padding: 72px 30px 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  row-gap: 60px;
  background-color: #f4f4f4;
  overflow: hidden;

  @media(max-width: 911px) {
    padding: 36px 16px;
  }
}

.discover-container {
  display: flex;
  row-gap: 17px;
  max-width: 1210px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btn {
    background: #FEDB00;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'urbano-regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;

    @media (max-width: 911px) {
      width: 100% !important;
    }
  }
}

.title-head {
  font-family: 'urbano-regular';
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  flex-wrap: nowrap !important;
  color: #000000;
  @media (max-width: 911px) {
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    max-width: 18ch;
  }
}

.title-head-discover {
  font-family: 'urbano-regular';
  color: #f0512c;
  font-style: italic;
  font-weight: 600;
}

.title-sub {
  font-family: 'urbano-regular';
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  text-align: center;
  max-width: 75ch;

  @media (max-width: 911px) {
    font-size: 16px;
    line-height: 20px; 
    text-align: start;
  }
}

.car-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.car-list {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  max-width: 1180px;
  overflow: hidden;
  flex-wrap: nowrap;

  .btn {
    &:focus {
      box-shadow: none;
    }
  }
}

.inner-car-slider {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.flip {
  transform: scaleX(-1);
}
