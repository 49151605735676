.bubble-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.fuel-cost-boxes {
       @media(max-width:450px) {
              flex-wrap: wrap;
       }
}
.eGallon {
       display: flex;
       font-size: 14px;
       justify-content: center;
       color: #142C41;
}
