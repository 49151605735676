.btn-open-filter {
    background: #FEDB00;
    width: 100%;
    padding: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: black;
    border: none;

    @media(max-width: 911px) {
        margin: 0px 0 71px -2px;
    }
}

.button-container {
    @media(max-width: 911px) {
        width: 100%;
    }
}


.fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    border: 0;
    overflow: hidden;

    font-family: 'urbano-regular';
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;


    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background: #F4F4F4;

        border: 0px; 
        border-radius: 0px;
        overflow: scroll;


        .modal-header {
            background: #0072CE;
            color: #fff;

            .modal-title {
                font-family: source-sans-pro, sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 32px;
                text-align: left;
            }

            .close {
                color: #fff !important;
                opacity: 1;
                text-shadow: 0;
            }
        }

        .modal-body {
            .form-group {
                background: #F4F4F4 !important;
            }

            .modal-footer {
                .btn-footer {
                    background-color: #FEDB00!important;
                    border: none;
                    height: 54px;

                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    text-align: center;

                }
            }
        }
    }
}

