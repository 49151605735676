#ev-jumbotron-title {
  h1 {
    font-family: 'urbano-regular';
    text-transform: uppercase;
    color: #0072ce;
    font-weight: 400;
    line-height: 1em;
    margin: 20px 0 0;
  }

  h2 {
    font-family: 'urbano-light';
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1em;
    margin: 0;
  }
}
