@import 'src/client_customizations/scss/client_master.scss';
.Footer {
  background-color: $GenericFooter-background-color;
  color: $GenericFooter-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 30px;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  .social-container,
  .disclaimer-container,
  .logo-container,
  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'urbano-regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .text-container {
     flex-direction: column;

     p {
          &:first-child {
            margin-bottom: 10px;
          }
     }
  }

  .social-container {
    gap: 24px;
  }

  .disclaimer-container {
    gap: 4px;
  }

  @media (max-width: 991px) {
    padding: 60px 16px;
  }

  a {
    color: rgb(255, 255, 255);
    text-decoration: underline;
    white-space: nowrap;
    margin: 0px 20px 15px 0px;
  }
  .disclaimer_container {
    font-family: Arial, Helvetica;
    display: flex;
    align-items: center;
    margin-left: '5px';
    font-size: 10px; /* Set the font size to 10px */

    div {
      background-color: transparent !important;

      img {
        max-width: 50%;
        margin: 0 0 15px;
      }

      p {
        color: white;
      }
    }
  }

  .mobile-disclaimer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .zappy-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: center;

      img {
        margin: 0 4px;
      }
    }
  }

  .subtext {
    font-family: 'BentonSans' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    text-transform: uppercase;
  }
  .horizontal-line {
    margin: 40px 0;
    height: 1px;
    width: 100%;
    background-color: #d2d3d5;
  }

  .social-media-row {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: space-between;

    @media (max-width: 1050px) {
      flex-wrap: wrap;
    }

    .privacy-links {
      font-size: 16px;
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      position: relative;
    }
    .social-section {
      font-size: 16px;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      margin-top: 0;

      @media (max-width: 450px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }

      a {
        margin: 0 10px;
        img {
          height: 20px;
        }
      }
    }
  }

  .legal-info {
    text-align: left;
  }
}
