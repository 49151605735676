.page-wrapper {
  padding: 38px 0px;

  .page-container {
    .text-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-family: 'urbano-regular';
        font-size: 46px;
        font-weight: 400;
        line-height: 1.3em;
        text-align: center;
        color: black;
        margin-bottom: 16px;
        max-width: 50ch;
      }

      h2 {
        font-family: 'urbano-regular';
        font-size: 28px;
        font-weight: 400;
        line-height: 1.3em;
        text-align: center;
        color: #2a2a2a;
        max-width: 50ch;
        text-align: center;
      }
    }

    .emissions-wrapper {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      margin: 52px 0 72px;
      gap: 30px;

      @media (max-width: 768px) {
        flex-direction: column;
        padding: 0 15px;
      }

      .emissions-filters-container {
        min-width: 200px;
        padding: 24px;
        background: white;
        box-shadow: 0px 20px 41px 0px #0000001a;
        gap: 8px;

        @media (max-width: 768px) {
          width: 100%;
        }

        p {
          font-family: 'urbano-regular';
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          text-align: left;
          color: #2b2b2b;
        }
      }
      .chart-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
        }

        .chart-container {
          background: white;
          padding: 24px;
          width: 840px;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;

          border: 1px solid #aeaeae;

          @media (max-width: 768px) {
            width: 100%;
            margin: 0 0 20px;
          }

          p {
            //styleName: H2 Subtitle;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            color: #2a2a2a;
          }
        }

        & .big {
          justify-content: flex-start;
        }

        .power-mix-container {
          width: 750px;
          height: 360px;

          @media (max-width: 768px) {
            width: 100%;
            height: 500px;
          }
        }

        .average-container {
          width: 305px;
          height: 300px;

          @media (max-width: 768px) {
            width: 100%;
            height: 600px;
          }

          .bar-container {
            &.desktop {
              width: 305px;
              height: 702px;
              transform: rotate(90deg);
              position: absolute;
              bottom: -188px;
              display: block;

              @media (max-width: 768px) {
                display: none;
              }
            }

            &.mobile {
              display: none;

              @media (max-width: 768px) {
                display: block;
                width: 100%;
                height: 600px;
                position: relative;
                bottom: 0;
              }
            }
          }

          canvas {
            width: 100% !important;
            height: 100% !important;

            @media (max-width: 768px) {
              height: auto !important;
            }
          }
        }
      }
    }
  }
}
