@import 'src/client_customizations/scss/client_master.scss';

.homepage-banner {
  background-image: url('../../../client_customizations/assets/images/homepage_banner.png');
  height: 38px;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage-jumbotron {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  background-image: url('../../../client_customizations/assets/images/homepage_banner.png');

  .btn {
    background: #FEDB00;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'urbano-regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;

  }

  h1 {
    font-family: 'urbano-regular';
    font-size: 120px;
    font-weight: 700;
    line-height: 120px;
    text-align: left;
    color: white;
  }

  h2 {
    font-family: 'urbano-regular';
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: white;
  }

  @media (max-width: 911px) {
    height: 250px;
    padding-top: 0px;

    h1 {
      font-size: 40px !important;
      line-height: 46px !important;
    }

    h2 {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  img {
    display: flex;
    width: 100%;
    height: 220px;
  }

  .banner-subsection {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 17px;
    width: 100%;
    height: 134px;
    flex-shrink: 0;
    background-color: rgba(148, 140, 242, 0.5);
    padding: 47px;

    @media (max-width: 992px) {
      row-gap: 20px;
      flex-direction: column;
      padding: 30px;
      height: inherit;
    }

    .banner-subsection-text {
      color: #e4ebf7;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 128.571% */

      @media (max-width: 992px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .title-banner {
    padding: 0 199px;
    color: #fff;
    color: #e4ebf7;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    font-family: 'urbano-regular';
    margin: 10px 220px 0px 0px;

    @media (max-width: 992px) {
      line-height: inherit;
      margin: 0;
      padding: 20px;
    }
  }

  .title-banner-end {
    color: #142c41;
  }
}

#why-choose-evs {
  padding: 0rem 1rem;
}
.big-promise-caption {
  top: 0;
  left: 0;
  position: absolute;
  padding: 1.5rem 1rem;
}
#HomepageVehiclesCarousel {
  background-color: #f3f3f3;
}

.homepage-titles-container {
  margin: 0px 0px 40px 0px;
  display: flex;
  row-gap: 17px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 911px) {
    gap: inherit;
    flex-direction: column;

    .primary {
      font-size: 32px !important;
      line-height: 38px !important;
      color: #142C41;
    }
  }
}

.max-width {
  max-width: 877px;
}

.width100 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HomepageSubTitle {
  font-family: 'urbano-regular';
  color: #2a2a2a !important;
  font-weight: 400 !important;
  font-size: 28px !important;
  text-align: center;

  @media (max-width: 911px) {
    width: 95%;
    text-align: left;
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.primary {
  color: #000;
  font-family: 'urbano-regular';
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 119.149% */
  @media (max-width: 576px) {
    text-align: center;
    margin-bottom: 30px;
  }
}

.secondary {
  color: #000;
  font-weight: 300 !important;
}

.HomepageFaq {
  padding: 75px 0;
  display: flex;
  background-color: #f8f8f8;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5rem 17rem;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 992px) {
    margin: 2.5rem 2.5rem;
  }

  .HomepageFaqTitle {
    width: 65%;
    display: flex;
    gap: 10px;

    .questions {
      color: black;
      font-family: 'urbano-regular';
    }

    .answered {
      font-family: Futura PT;
      font-weight: 600;
    }

    @media (max-width: 766px) {
      gap: inherit;
      flex-direction: column;
    }
  }

  .HomepageFaqSubTitle {
    width: 65%;
    font-size: 20px;
    font-weight: 400;
    font-family: Futura PT;

    @media (max-width: 766px) {
      width: 95%;
      text-align: center;
    }
  }

  .MuiAccordion-root {
    width: 70% !important;
    background-color: #f8f8f8;
    box-shadow: inherit;
    border-radius: inherit !important;
    border-top: 1px solid #576116;
  }
}

.container-home {
  padding: 5rem 17rem;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-incentives {
  background-repeat: no-repeat;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  row-gap: 20px;
  padding: 82px 30px 104px;

  @media(max-width: 911px) {
    padding: 48px 16px;
  }
}

.homepage-incentives-title-container {
  display: flex;
  row-gap: 17px;
  max-width: 1100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage-incentives-title {
  display: flex;
  justify-content: flex-start;
  color: #000;
  font-family: 'urbano-regular';
  font-size: 46px;
  font-style: normal;
  font-weight: 300;
  line-height: 56px;
  @media (max-width: 911px) {
    text-align: center;
    font-size: 32px !important;
    line-height: 38px !important;
  }

  .highline {
    color: #000;
    font-weight: 400 !important;
  }
}

.homepage-incentives-title-sub {
  margin: 15px 0px 6px 0px !important;
  display: flex;
  justify-content: flex-start;
  font-family: 'urbano-regular';
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  text-align: center;

  @media (max-width: 911px) {
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: start;
  }
}

.homepage-incentives-bar {
  background-color: rgba(221, 240, 252, 0.5);
  mix-blend-mode: screen;
  height: 112px;
  width: 100%;
}

.carousel-incentives-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 10px;

  @media (max-width: 991px) {
    column-gap: 10px;
    row-gap: 10px;

    .col-sm-1 {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .arrow {
      width: 24px;
      height: 24px;
      font-size: 0px;

      button{
        padding: 0;
        font-size: 0;
        line-height: 0;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.incentives-list {
  display: flex;
  width: 100%;
  max-width: 830px;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;
  flex-wrap: wrap;

  &.less {
    flex-wrap: nowrap;
  }

  @media (max-width: 992px) {
    column-gap: 10px;
    row-gap: 10px;
  }
}

.homepage-home-charger-banner {
  background-color: rgba(65, 52, 130, 0.853);
  padding-bottom: 85px;
}

.homepage-home-charger-title-container {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  padding: 5.3rem 0rem 3.75rem 0rem;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-home-charger-title {
  color: #ffffff;
  font-family: 'BentonSans';
  font-size: 47px !important;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  max-width: 877px;

  .highline {
    color: #142c41;
    font-family: 'BentonSans';
    font-size: 47px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.homepage-home-charger-title-sub {
  font-family: 'BentonSans';
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.homepage-home-charger-get {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    background: #FEDB00;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'urbano-regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  a, .btn {
    text-decoration: none;

    @media (max-width: 911px) {
      width: 100% !important;
    }
  }
}

.home-map-section-container {
  padding: 0;
  background: #f4f4f4;

  .home-map-section {
    max-width: 1200px;
    padding: 72px 0 85px;
    margin: 0 auto;
    @media (max-width: 992px) {
      margin: 0rem 16px;
      padding: 48px 0px;
    }
  }
}
.map-section-container {
  padding: 0;
  .home-map-section {
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 992px) {
      margin: 2.5rem 2.5rem;
    }
  }
}
