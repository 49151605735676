.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-switch {
  .name {
    font-family: 'urbano-regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #2a2a2a;
    margin-left: 8px;
  }

  .switch .react-switch-bg {
    border: 1px solid #0072ce;
  }

  .react-switch-handle {
    width: 20px !important;
    height: 20px !important;
  }
}
