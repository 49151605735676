@import 'src/client_customizations/scss/client_master.scss';

#faqContainer {
  .faq {
    font-family: 'urbano-regular';
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 48px;
  }

  .collapsibleSectionCollaspeButton {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
  }

  .contentShow .collapsibleSectionBodyContent {
    opacity: 1;
    height: 100%;
    transition: all 0.8s linear;
  }

  .contentHide .collapsibleSectionBodyContent {
    height: 0;
    opacity: 0.01;
    transition: opacity 15ms linear;
  }

  .collapsibleSection {
    > div {
      margin-bottom: 2rem;
    }

    .collapsibleSectionHead {
      .collapsibleSectionHeadLine {
        font-family: 'urbano-regular';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #333333;
        width: 96%;
      }
    }

    .collapsibleSectionBodyContent {
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 1.5rem;

      br {
        content: '';
        margin: 2em;
        display: block;
        font-size: 24%;
      }

      img {
        max-width: 100%;
      }
    }

    .collapsibleSection {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  table {
    tr,
    th,
    td {
      border: 1px solid black;
    }
    th {
      text-align: center;
      padding: 4px;
    }
    td {
      width: 80%;
      text-align: center;
      padding: 4px;
    }
    .incentive-table-subheader {
      text-decoration: underline;
    }
  }
}
