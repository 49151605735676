.FAQCard {

     &.faq-card-reverse {
          .row.container {
               flex-direction: row-reverse;

               @media (max-width: 991px) {
                    flex-direction: column-reverse !important;
               }
          }
     }

  .sideBySide {
    margin: 3rem 0rem;
    .row-container {
      max-width: 100% !important;
      flex-direction: column !important;
    }

    font-family: 'urbano-regular';
    display: flex;
    flex-direction: row;
    justify-content: center;

    .container {
      display: flex;
    }

    .row {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: nowrap;
      gap:30px;

      @media (max-width: 1208px) {
        max-width: 720px;
      }

      @media (max-width: 991px) {
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }

    .text {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      background-color: white;
      font-size: 16px;
      padding: 2rem;
      font-weight: 150;

      a {
        text-decoration: underline;
        color: black;
      }

      @media (max-width: 991px) {
        width: 100%;
        margin: 0;
      }

      .text-title {
        display: flex;
        font-weight: 400;
        font-size: 35px !important;

        @media (max-width: 991px) {
          font-size: 25px !important;
        }
      }

      .small-space {
        margin-top: 1rem;
        font-size: 18px;
        line-height: 1.5em;
      }

      .large-space {
        margin-top: 1rem;
      }

      .bottom,
      .top,
      .list {
        font-family: 'urbano-regular' !important;
      }
      .list {
        li {
          margin: 10px 0px;
        }
      }
    }

    .image {
      width: 50%;
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        width: 100%;
      }

      img {
        width: 100%;
        display: block;
        height: auto;
        margin: 0 auto;
      }
      .icon {
        @media (max-width: 1150px) {
          width: 99%;
        }
      }
    }
  }
}
