.form-check-label {
  margin: 4px 0 0 6px !important;
}

.map-container-wrapper {
  display: flex;
  width: 867px;
  flex-direction: column;
}

.disclaimer-container {
  margin-top: 10px;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.input-working-zipcode {
  padding-left: 6px;

  @media (max-width: 911px) {
    padding-right: 6px;
  }
}

.map-wrapper {
  padding-left: 8px;

  @media (max-width: 911px) {
    padding-right: 8px;
  }
}

.map-control-wrapper {
  @media (max-width: 911px) {
    width: 100% !important;
    padding: 0 !important;
  }
}
