.zip-options {
  width: 568px;

  @media(max-width: 911px) {
    width: 100%;
  }

  label {
    font-family: 'urbano-regular';
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.8px;
    text-align: left;
    color: black;
    margin-bottom: 0 !important;
    text-transform: capitalize !important;
  }

  .input-group {
    @media(max-width: 911px) {
      margin-bottom: 0 !important;
    }
    input {
      height: 40px;
      font-family: 'urbano-regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
      width: 568px;

      @media(max-width: 911px) {
        width: 100%;
        background: white !important;
        height: 42px;
      }
    }
  }
}

.power-options {
  label {
    color: #172536 !important;
    font-family: 'urbano-regular';
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19.2px;
    text-align: right;
    margin-left: 8px !important;
  }
}

.button-options {
  @media(max-width: 911px) {
    gap: 12px;

    button {
      margin: 0;
    }
  }
  button {
    width: 271px;
    height: 40px;
    background-color: #0072ce;
    color: white;
    font-family: 'urbano-regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    border: none;

    @media(max-width: 911px) {
      width: 100%;
      max-width: 100%;
    }
  }
  
}

.map-options {
  gap: 24px;
  padding: 8px 0px 12px;

  button {
    margin: 18px 0 0;
  }

  @media(max-width: 911px) {
    gap: 12px;

    .form-group {
      width: 100% !important;
    }

    button {
      margin: 0;
    }
  
  }
}
