@import 'src/client_customizations/scss/client_master.scss';

.ComparedCarDetails {
  margin: 1.6rem 0;
  text-align: center;
  .btn {
    margin-top: 1.5rem;

    &.btn-detail {
      color: #0072ce;
      text-transform: uppercase;
      font-weight: bold;
      font-family: 'urbano-bold';
      font-size: 18px;
    }
  }

  .title {
    color: #142c41;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    font-family: 'urbano-light' !important;
    margin-bottom: 10px;
  }

  .value {
    color: #142c41;
    text-align: center;
    /* H4 Sub Section Title */
    font-family: 'urbano-regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .bigger {
    font-size: 32px !important;
  }

  p {
    margin-bottom: 0;
  }
  .afterIncentives {
    .DetailColumn {
      border-top: 1px solid #dbdbdb;
    }
  }
  .row {
    display: flex;
    justify-content: center;

    &.afterIncentives {
      .DetailColumn {
        border-top: none;
      }
    }

    .DetailColumn {
      top: 0.4rem;
      padding: 1rem 0 1rem 0;
      border-bottom: 1px solid #dbdbdb;
      text-align: center;
    }
  }

  .h5 {
    font-size: 24px;
    font-weight: 600;
    color: $gray;
  }
}
