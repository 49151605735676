@import 'src/client_customizations/scss/client_master.scss';

.btn-arrow {
  font-family: 'urbano-regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  padding: 0;
  color: black;
}

.IncentivePreferences {
  .h5 {
    .badge {
      font-size: 50%;
      vertical-align: middle;
    }
  }
  .tooltip-bubble {
    min-width: 250px;
    max-width: 250px;
  }
  .input-well {
    background-color: $IncentivePreferences-input-well-background-color;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    border: none;
    .btn {
      width: 100%;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .grid-item {
    padding: 20px;
    div {
      div {
        margin-top: 1px !important;
        margin-bottom: 0px !important;
      }
    }
  }
  .pushed-up {
    margin-top: -5px;
  }
  .centered {
    display: flex;
    justify-content: center;
  }
  .personalize-button {
    padding: 15px 40px;
    background-color: #0072ce;
    color: #fff;
    border-radius: 5px;
    font-weight: 400;
    font-size: 15px;
    border: none;
    font-family: 'urbano-regular';
    text-transform: capitalize;
    width: 100%;
  }
  .label-select {
    text-align: left;
    margin: 0 0 10px;
    font-style: normal;
    line-height: 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    color: #142c41;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
  }
}

.modal-wide-content {
  .modal-header .modal-title {
    font-family: Gotham, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 17.23px;
    text-align: center;
    color: #2a2a2a;
  }
}
