.impact-wrapper {
  width: 100%;
  padding: 82px 0 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  @media(max-width: 911px) {
    padding: 48px 16px;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'urbano-regular';
      font-size: 46px;
      font-weight: 400;
      line-height: 56px;
      text-align: center;
      color: black;

      @media(max-width: 911px) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    h2 {
      font-family: 'urbano-regular';
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
      color: #2a2a2a;
      max-width: 62ch;

      @media(max-width: 911px) {
        font-size: 16px;
        line-height: 20px;
        text-align: start;
      }
    }

    .btn-container {
      width: 160px;
      margin-top: 24px;

      @media(max-width: 911px) {
        width: 100%;
      }
    }
  }

  .bottom-container {
    margin-top: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;

    @media(max-width: 911px) {
      flex-direction: column-reverse;
    }

    .bar-chart-wrapper {
      width: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media(max-width: 911px) {
        flex-direction: column-reverse;
        border: 1px solid #AEAEAE;
        padding: 32px 0;
        width: 100%;
        align-items: center;
        justify-content: center;

      }

      .bar-container {
        width: 305px;
        height: 332px;
      }

      p {
        margin-top: 20px;
        font-family: Roboto, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #333;
      }

      span {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #363a36;
      }
    }

    .Doughnut-chart-wrapper {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;

      @media(max-width: 911px) {
        flex-direction: column-reverse;
        border: 1px solid #AEAEAE;
        padding: 32px 0;
        width: 100%;
        align-items: center;
        justify-content: center;

        .Doughnut-container {
          margin-bottom: 0px !important;
        }
      }

      .Doughnut-container {
        height: 300px;
        width: 530px;
        margin-bottom: 50px;

        @media(max-width: 911px) {
          width: 320px;
        }
      }

      p {
        font-family: Roboto, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #333;
      }

      span {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #363a36;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

      }
    }
  }
}
