@import 'src/client_customizations/scss/client_master.scss';

.EVCard {
  max-width: 255px;
  min-height: 423px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.EVCard p {
  font-family: 'urbano-regular';
  width: 100%;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .EVCard {
    min-height: 250px;
    max-width: 90% !important;
  }
}

.EVCardTop,
> * {
  padding: 8px;
}

.EVCardTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  position: relative;
  row-gap: 10px;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .badge-locally-available,
  .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 12px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }

  @media(max-width: 911px) {
    padding: 12px;
  }
}

.auto-margin {
  margin-top: auto;
}

.EVCardBottom {
  display: flex;
  flex-direction: column;
}

.EVCardBottomWithLink {
  width: 100% !important;
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn-detail {
    width: 100%;
  }

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

@media (min-width: 576px) {
  .EVCardTop {
    padding: 15px 15px 10px 15px;
  }

  .EVCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}

.EVCard-incentive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  .EVCard-incentive-value {
    font-family: 'urbano-regular';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: right;

    color: #2a2a2a;
    width: 50%;
  }
}

.EVCard-label {
  font-family: 'urbano-regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
}

.EVRowOfData {
  display: flex;
  margin-bottom: 4px;

  .EVRowOfData-value {
    text-align: right;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.4000000059604645px;
    color: #2a2a2a;
  }
}

.brand {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #2a2a2a;
}

.model {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #2a2a2a;
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.trim {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #595959;
}

.divider {
  border-top: $EVCard-EVCardBottom-border;
  margin: 0 15px;
}

.btn-more-details {
  height: 40px;
  background: #fedb00;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'urbano-regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: black;
}
