.CostOfOwnership {
  padding: 50px 0px 0px;
}

#cost-of-ownership-sidebar {
  .zip-code-input-container {
    margin: 0 0 20px;
    label {
      text-transform: uppercase;
    }
  }
}

#cost-to-own-comparison {
  .comparison-graph-container {
    width: 100% !important;
    margin: 25px 0 0;
  }
}
