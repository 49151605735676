@import "src/client_customizations/scss/client_master.scss";

.lead-gen-sidebar {
    position: fixed;
    position: sticky;
    top: 40%;
    transform: rotate(270deg);
      transform-origin: left top 0;
    height: 0;
    z-index: 10;

  .join-network-button {
    background-color: #EE1C2E;
    color: #fff;
    padding: 0.25em 1.75em;
    font-size: 18px;
    border: none;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.lead-gen-modal {
  .modal-content {
    background-color: #F6F8FB;
  }
  .modal-header {
    padding: 0;
    border-bottom: 0;
    .close {
      padding: 12px 12px 0px 0px;
      margin: 0;
    }
  }
  .title-modal {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 700;
  }
  .modal-subtitle {
    font-size: 14px;
    font-weight: 500;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    input {
      margin-bottom: .25rem;
      padding: 8px;
      font-size: 16px;
    }
    select {
      padding-left: 8px;
      font-size: 16px;
      margin-bottom: .25rem;
    }
    .hear-about {
      padding-top: 20px;
      padding-bottom: 12px;
      font-size: 13px;
    }
    .hear-about-group {
      padding-bottom: 12px;
      font-size: 15px;
      input {
        margin-right: 8px;
      }
    }
  }
}

.join-ev-network {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;

  .btn-pseg {
    width: 175px;
  }
  .xcel-input {
    width: 412px;
    height: 40px;
  }
  .hear-about-group {
    display: flex;
    flex-direction: column;
  }
  .form-error {
    color: red;
  }
  .submit {
    align-self: center;
    margin-bottom: 12px;
  }
  input[type=checkbox] {
    margin-right: 8px;
  }
  .legal-disclaimer {
    font-size: 15px;
    color: black;
  }
}

@media (max-width: 991px) {
  .lead-gen-sidebar {
    top: calc(100% - 30px - 112px);
    .join-network-button {
      background-color: #EE1C2E;
    }
  }
  .join-ev-network {
    .xcel-input {
      width: 280px;
      height: 40px;
    }
  }
  .lead-gen-modal {
    .modal-subtitle {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
