@import 'src/client_customizations/scss/client_master.scss';

.CompareVehicles {
  background-color: #f4f4f4;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;

  .bgHolder {
    width: 100%;
    height: 970px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f4f4f4;

    // z-index: -1;
    @media (max-width: 576px) {
      height: 945px;
    }
  }

  .compared-vehicle-container {
    background-color: #ffffff !important;
    padding-top: 20px;
    overflow: hidden;
  }

  .single-vehicle {
    padding-top: 50px;
    padding-bottom: 50px;

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 16px;
    }
  }

  .BreakevenChart-section-container {
    display: flex;
    padding: 2rem 25rem;
    justify-content: flex-end;

    .BreakevenChart-container {
      width: 825px;
    }
  }
}
