@import 'src/client_customizations/scss/client_master.scss';

.TravelRouteMap .map-container {
  position: relative;
  height: 552px;
  width: 100%;
  border: $ChargingMap-dealer-map-container-border;
  border-radius: $ChargingMap-dealer-map-container-border-radius;
}

.TravelRouteMap .route-distance-legend {
  background: black;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  font-size: 0.75em;
  color: white;
  bottom: 0;
  left: 0;
}

.TravelRouteMap label {
  font-weight: 700;
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 0;
}

.TravelRouteMap {
  .col-sm-12 {
    padding: 0;
  }
}

@media (min-width: 576px) {
  .LegendContainer {
    display: flex;
    height: 59px;
  }
}

.LegendContainer {
  margin: 19px 0 0;

  width: 100%;
  padding: 0px, 80px, 0px, 80px;
  border: 1px;
  gap: 80px;

  border: 1px solid #aeaeae;

  justify-content: center;
  align-items: center;
  gap: 80px;

  .legendItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      margin-left: 8px;

      b {
        font-family: 'urbano-regular';
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }

      span {
        font-family: 'urbano-regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
