.MatchScoreOptions {
  margin-bottom: 16px;
  width: 270px;
  @media (max-width: 768px) {
    background-color: #e3ebf7;
  }

  .h3 {
    font-family: 'urbano-regular';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #2a2a2a;
  }
  .h2 {
    font-family: 'BentonSans', Helvetica, sans-serif;
    color: #142c41;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }

  .age-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    p {
      font-family: 'urbano-regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
      color: black;
    }

    .btn-container {
      width: 100%;
      display: flex;

      button {
        box-shadow: 0px 20px 41px 0px #0000001a;
        width: 50%;
        height: 59px;
        background-color: white;
        border: none;

        font-family: 'urbano-regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;

        color: black;

        &.active {
          background: #0072ce;
          color: white;
        }
      }
    }
  }
}

.input-well {
  border-radius: 0;
  padding: 24px 20px 16px;
}
