.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  font-family: 'urbano-regular' !important;
}

.carDetailContainer {
  padding: 50px 15px 30px;
  background: #ffffff;

  @media (max-width: 768px) {
    padding: 25px 0px 30px;
  }
}

.carDetailsTable {
  font-family: 'urbano-regular';
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  color: #3d3935;

  @media (max-width: 768px) {
    row-gap: 10px;

    .carDetailsFooter {
      margin-top: 1rem !important;
    }
  }

  @media (min-width: 576px) {
    .carDetailsFooter {
      margin-top: 1rem;
    }
  }

  .title {
    margin: 0;
    font-size: 16px;
  }
}
