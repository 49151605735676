.catalog-cards-container {
  column-gap: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  max-width: 825px;

  @media(max-width: 911px) {
    align-items: center;
    justify-content: center;
  }
}
