@import 'src/client_customizations/scss/client_master.scss';

.small-centered.mb-bottom {
  margin-bottom: 50px;
}

.EVsSort {
  @media (min-width: 576px) {
    margin-bottom: 15px;
  }

  .form-inline {
    width: 190px;

    align-items: flex-start;
    flex-direction: column;
    @media (max-width: 911px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    #vehicle-sort-dropdown {
      margin-right: 0 !important;
      @media (max-width: 576px) {
        width: 100% !important;
        height: 62px;
      }
    }

    select {
      width: 182px !important;
    }
  }

  label {
    font-family: 'urbano-regular';
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    text-align: left;
    color: #1a1a1a;
  }
  select {
    background: $white;
    border: 1px solid #b9bcc0;
    border-radius: 4px;
    font-size: 16px;
    color: #1a1a1a;
  }
}

.ev-toggle {
  @media (min-width: 576px) {
    width: 60%;
  }
  padding: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin: 50px 0 35px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);

  a {
    @media (min-width: 576px) {
      width: 50%;
    }

    text-align: center;
    color: #142c41;
    background-color: #fff;
    padding: 20px 0px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s ease-out;

    &.active {
      // background: #0081c6;
      color: #fff;
    }

    &:hover {
      text-decoration: none;
      // background-color:#E3001C;
      // color:#fff;
    }
  }
}
.sub-header {
  font-family: 'urbano-regular';
  font-size: 22px;
  font-weight: 300;
}

@media (min-width: 576px) {
  .EVsSort {
    float: right;
  }
}

.container {
  .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .header {
    font-family: 'urbano-regular';
    font-size: 46px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    color: #142C41;

    @media(max-width: 911px) {
      font-size: 32px;
      line-height: 38.4px;
    }
  }

  .sub-header {
    font-family: 'urbano-regular';
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: #2a2a2a;
    max-width: 42ch;

    @media(max-width: 911px) {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
}

.vehicle-catalog-wrapper {
  display: flex;
  margin-top: 96px;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;

  @media(max-width: 911px) {
    flex-direction: column;
    margin-top: 16px;
  }

  .evsort-row {
    width: 825px;
    display: flex;
    align-items: center;
    justify-content: end;

    @media(max-width: 911px) {
            width: 100%;
    }
  }

  .catalog-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -100px;
    @media(max-width: 911px) {
      margin-top: 0px;
    }
  }
}

.filter-container {
  @media(max-width: 991px) {
    width: 100%;
  }
}

.condition-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 16px;
  background: white;
  margin-bottom: 24px;

  button {
    width: 254px;
    height: 48px;
    background: white;

    font-family: 'urbano-regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    cursor: pointer;

    border: none;

    color: #2a2a2a;

    &.active {
      background: #0072ce;
      color: white;
    }

    @media(max-width: 991px) {
      width: 100%;
    }
  }

  @media(max-width: 911px) {
    width: 100%;
    margin-bottom: 0px;
  }
}

.modal-btn-container {
  width: 100%;
  gap: 16px;
  display: flex !important;
  flex-direction: column;
}