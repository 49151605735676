@import "src/client_customizations/scss/client_master.scss";

.ButtonGroup {
	.gap {
		gap: 8px;
	}
	
	.btn {
		background-color: transparent !important;
		color: #142C41 !important;
		border: 1px solid #9BA4B8 !important;
		
		&.modal-btn{
			width: 50% !important;
			height: 64px !important;
		}

		&:hover {
			border: 1px solid #0072CE !important;
		}

	}
	.btn.active {
		background-color: #0072CE !important;
		color: white !important;
	}
}
