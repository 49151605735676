@import "src/client_customizations/scss/client_master.scss"; 

.ComparedVehicleToggle {
  padding: 10px;
  border:1px solid #AEAEAE;
  border-radius: 5px;
  flex-direction: column;
  background-color: white;
    .btn-group {
    width: 100%;
    }
   .btn {
    flex: 1;
    line-height: 1em;
    color: $CompareVehicleToggle-MobileToggle-button-color;
    background-color: $CompareVehicleToggle-MobileToggle-button-fontcolor;
    padding: 1rem 0.75rem;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .btn-select{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
    .btn.active {
    background-color: $CompareVehicleToggle-MobileToggle-button-color-active;
    color:$CompareVehicleToggle-MobileToggle-button-fontcolor-active;
  }
}
  