.ChargingStationsMapLegend {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;
  width: 100%;

  .charger-type {
    position: relative;
    padding-left: 3rem;
    margin-bottom: 1rem;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      @media(max-width: 911px) {
        flex-direction: row;
        gap: 4px;
      }
    }
  }

  .charger-type:last-child {
    margin-bottom: 0;
  }

  .charger-type img {
    width: 25px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 24px;
    gap: 12px;

    .charger-type {
      margin: 0px !important;
      img {
        height: 36px !important;
        width: auto !important;
        top: -11px !important;
      }
    }
  }
  
  @media (max-width: 450px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  
    .charger-type {
      margin: 6px 0px !important;
    }
  }
}
