.evHead {
  font-family: 'urbano-regular';
  font-size: 46px;
  font-weight: 400;
  line-height: 56px;

  color: #000;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 38px;
    color: #142C41;
  }
}

.main-container {
  justify-content: center;

  .options-container {
    @media(max-width: 911px) {
      width: 83%;
    }
  }
}

.lead {
  font-family: 'urbano-regular';
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;

  color: #2a2a2a;

  max-width: 931px;
  margin: 0 auto 50px;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 19px;
    color: #142C41;
    margin: 0 16px;
  }
}

.disclaimer {
  margin-top: 16px;

  font-family: 'urbano-regular';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.score-options-header {
  font-family: 'urbano-regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #2a2a2a;
  margin-bottom: 0;
}

.incentives-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 840px;
  top: -54px;
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 840px;

  @media(max-width: 911px) {
    display: none;
  }
}
