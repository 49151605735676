@import 'src/client_customizations/scss/client_master.scss';
$color_1: #666666;
$color_2: #333333;
$color_3: #252525;

.RenderItemTitle {
  font-size: 13px;
  font-weight: 200;
  margin: 0;
  text-align: center;
  color: #595959;

  &.left-align {
    text-align: left;
  }

  &.mb-10 {
    margin-bottom: 20px;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
.uppercase {
  text-transform: uppercase;
}
.RenderItemValue {
  font-weight: bold;
  color: $RenderItem-item-value-color;
  .denominator {
    font-weight: 700;
    font-size: 0.8rem;
    color: $RenderItem-item-value-denominator-color;
    opacity: 0.45;
  }
}
.h2.RenderItemValue {
  font-size: 1.75rem;
}
p.RenderItemValue {
  font-size: 24px;
  padding-top: 0px !important;
}
