.ChargingStationsList {
  margin-top: 2em;
  text-align: center;

  .show-full-list-container {
    button {
      border: none;
      background: #0072CE !important;
      color: white !important;
      font-family: 'urbano-light';
    }
  }

  .charging-station-cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .station-table {
      width: 100%;

      .table-header {
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
      .station-table-header {
        text-align: start;
        padding: 16px;
      }

      tbody {
        .station-table-cell {
          height: 72px;
          text-align: start;
          padding: 16px;
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
      }
    }
  }
}
