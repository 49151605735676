h1.title-page.pad-top {
  padding-top: 2rem;
}

h2.mb-bottom {
  margin-bottom: 50px;
}
.asked-questions {
  @media (max-width: 992px) {
    margin: 2.5rem 2.5rem;
  }
}

.FAQ .container.nopadding-top {
  padding-top: 0;
}

.faq-jumbotron {
     position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
  background-image: url('../../client_customizations/assets/images/faq-banner.jpg');
  background-position: center center;
  padding-bottom: 80px;

  &:before {
     z-index: 1;
     content:"";
     width:100%;
     height:100%;
     top: 0;
     position: absolute;
     right: 0;
     background-color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 576px) {
    height: 380px;
  }

  .title-banner {
    padding: 0 199px;
    color: #fff;
    color: #e4ebf7;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    font-family: 'urbano-regular';
    margin: 10px 220px 0px 0px;

    @media (max-width: 992px) {
      line-height: inherit;
      padding: 40px;
    }
  }
  .title-faq {
     position: relative;
     z-index: 2;
     font-family: 'urbano-bold';
    font-size: 45px;
    font-weight: bold;
    line-height: 1.2em;
    letter-spacing: 0em;
    color: #142c41;
    padding: 0px 0 20px;
    margin:0;
    text-align: center;
    color: #ffffff;

    @media (max-width: 480px) {
      padding: 30px 0 10px;
    }
  }

  .subtitle-faq {
     position: relative;
     z-index: 2;
    width: 450px;
    font-family: 'urbano-regular';
    font-size: 25px !important;
    font-weight: 400;
    line-height: 1.1em;
    color: #142c41;
    text-align: center;
    color: #ffffff;
    margin:0;

    @media (max-width: 480px) {
      width: 270px;
      font-size: 18px !important;
    }
  }
}
.faqBgHolder {
  width: 100%;
  height: 744px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: -1;
}

.collapsibleSection {
  .collapsibleSectionBodyContent {
     .img-2-col {
          margin:30px 0;
          display: flex;
          gap:30px;
          justify-content: center;
     }

    .faq-col-3 {
      display: flex;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }

    p.centered-text {
      text-align: center;
    }

    a {
      color: black;
      text-decoration: underline;
    }
    img.align-center {
      display: block;
      margin: 15px auto;
      max-width: 100%;
    }
  }
}
